<template>
  <v-app>
    <v-content>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="4"
            sm="8"
          >
            <v-form
              @submit.prevent="doLogin"
              ref="form"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  color="app"
                  dark
                  flat
                >
                  <v-toolbar-title class="text-center full-width">
                    Iniciar Sesión
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    :rules="[rules.required]"
                    autocomplete="off"
                    color="white"
                    label="Usuario"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="user"
                  />

                  <v-text-field
                    :rules="[rules.required]"
                    autocomplete="off"
                    color="white"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    :loading="botonLoading"
                    color="app"
                    type="submit"
                  >
                    Entrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex'
  import { required } from '../../utils/rules'

  export default {
    name: 'LoginIndex',

    data: () => ({
      // user: 'admin',
      // password: 'secret',
      user: '',
      password: '',
      botonLoading: false,
      rules: { required },
    }),
    methods: {
      ...mapActions(['login']),
      doLogin () {
        const isValid = this.$refs.form.validate()
        if (!isValid) {
          return
        }
        this.botonLoading = true
        const payload = {
          username: this.user,
          password: this.password,
        }
        this.login(payload).then(() => {
          this.$toast.success('Bienvenido.')
          this.$router.push('/dashboard')
        }).finally(() => {
          this.botonLoading = false
        })
      },
    },
  }
</script>
